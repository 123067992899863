/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from "@benefit-systems/common-components";
import { FC } from "react";

import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { BENEFIT_PRODUCT_TYPES } from "web/constants/benefits";

import { IProduct, MBProductType } from "web/types/Product";

import classes from "../productCard.scss";
import PointsStatus from "./PointsStatus";
import Price from "./Price";
import UnavailableTooltip from "./UnavailableTooltip";
import { UnavailableTooltipVariants } from "./UnavailableTooltip/unavailableTooltip";

interface IProductCardFooterProps {
  className: string;
  isOutOfStock?: boolean;
  isAnnouncement?: boolean;
  price: number;
  finalPrice: number;
  payments: IProduct["payment_settings"];
  options: IProduct["product_options"];
  type: IProduct["mb_product_type"];
  to: {
    pathname: string;
    search: string;
    state: { categoryId: string; clickFrom?: string };
  };
  priceTourismDomestic: number;
  onClick: () => void;
  isUnavailable?: boolean;
}

const ProductCardFooter: FC<IProductCardFooterProps> = ({
  className,
  isOutOfStock,
  isAnnouncement,
  payments = [],
  price,
  finalPrice,
  options = {} as IProduct["product_options"],
  type,
  priceTourismDomestic,
  to,
  isUnavailable,
  onClick = () => {},
}) => {
  switch (type) {
    case MBProductType.UNIVERSAL_CODE: {
      if (isAnnouncement) {
        return null;
      }

      const isConfigurable =
        options &&
        options.configurable_options &&
        isArrayHasItems(options.configurable_options.attributes);
      return isOutOfStock ? (
        <UnavailableTooltip
          variant={
            isUnavailable
              ? UnavailableTooltipVariants.NA
              : UnavailableTooltipVariants.OOS
          }
        />
      ) : (
        <div className={className}>
          <Price
            finalPrice={finalPrice}
            isConfigurable={isConfigurable}
            price={price}
            to={to}
            dataT1="final_price"
            onClick={onClick}
          />
          <PointsStatus price={finalPrice} payments={payments} />
        </div>
      );
    }
    case MBProductType.INTERNATIONAL_TOURISM_TRAVELPLANET:
    case MBProductType.INTERNATIONAL_TOURISM_OTHER: {
      return (
        <strong className={classes.tourismPayInfo}>
          {__("Zapłać punktami lub środkami własnymi")}
        </strong>
      );
    }
    case MBProductType.ANIXE_TOURISM: {
      return priceTourismDomestic ? (
        <div className={className}>
          <Price
            finalPrice={priceTourismDomestic}
            isConfigurable
            price={priceTourismDomestic}
            to={to}
            onClick={onClick}
          />
        </div>
      ) : null;
    }
    case BENEFIT_PRODUCT_TYPES.GROUP_1:
    case BENEFIT_PRODUCT_TYPES.GROUP_2: {
      return finalPrice ? (
        <div className={className}>
          <Price
            finalPrice={finalPrice}
            isSubscription
            to={to}
            onClick={onClick}
          />
        </div>
      ) : (
        <div className={`${className} ${classes.checkButtonContainer}`}>
          {/* @ts-ignore */}
          <Button to={to} onClick={onClick} className={classes.checkButton}>
            {__("Sprawdź")}
          </Button>
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default ProductCardFooter;
