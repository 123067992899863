import { FC } from "react";

import TooltipIcon from "web/assets/icons/tooltip.svg";

import defaultClasses from "./toolTip.scss";

type VariantType = "top" | "right" | "bottom" | "left" | "top-left";
interface IToolTip {
  message: string | React.ReactNode;
  customStyles?: {
    root?: string;
    message?: string;
    tooltip?: string;
  };
  variant?: VariantType;
}

const ToolTip: FC<IToolTip> = ({
  message,
  customStyles = {},
  variant,
}: IToolTip) => {
  const messageVariant =
    variant && variant !== "top" ? defaultClasses[`message__${variant}`] : "";

  const messageClasses = `${defaultClasses.message} 
  ${customStyles.message ?? ""} 
  ${messageVariant}`;

  const tooltipClasses = {
    root: `${defaultClasses.root} ${customStyles.root ?? ""}`,
    tooltip: `${defaultClasses.tooltip} ${customStyles.tooltip ?? ""}`,
    message: messageClasses,
  };

  return (
    <div className={tooltipClasses.root}>
      <div className={tooltipClasses.tooltip}>
        <TooltipIcon />
        <div className={tooltipClasses.message}>{message}</div>
      </div>
    </div>
  );
};

export default ToolTip;
