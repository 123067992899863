// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-root-T2T{position:relative;display:block;overflow:hidden}.image-root-T2T::after{content:"";display:block;padding:0 0 65%}.image-image-Lde{position:absolute;z-index:1;left:0;right:0;bottom:0;top:0;margin:auto;width:100%;height:100%;-o-object-fit:cover;object-fit:cover;max-height:100%;max-width:100%;transition:transform .2s ease-in-out}.image-image-Lde:hover{transform:scale(1.1)}`, "",{"version":3,"sources":["webpack://./web/Components/Common/CodeItem/Image/image.scss"],"names":[],"mappings":"AAIuB,gBACrB,iBAAA,CACA,aAAA,CACA,eAAA,CAEA,uBACE,UAAA,CACA,aAAA,CACA,eAAA,CAIJ,iBACE,iBAAA,CACA,SAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,KAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA,CAAA,gBAAA,CACA,eAAA,CACA,cAAA,CACA,oCAAA,CAEA,uBACE,oBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  position: relative;\n  display: block;\n  overflow: hidden;\n\n  &::after {\n    content: \"\";\n    display: block;\n    padding: 0 0 65%;\n  }\n}\n\n.image {\n  position: absolute;\n  z-index: 1;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  top: 0;\n  margin: auto;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  max-height: 100%;\n  max-width: 100%;\n  transition: transform $transition-duration $transition-timing-function;\n\n  &:hover {\n    transform: scale(1.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `image-root-T2T`,
	"image": `image-image-Lde`
};
export default ___CSS_LOADER_EXPORT___;
