import { motion } from "framer-motion";
import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import LinkTransition from "web/Layout/Common/LinkTransition";
import __ from "web/Layout/Translations";

import { CategoryWithCount } from "web/Components/Common/Categories/Desktop/List/list";
import DropArrow from "web/Components/Common/DropArrow";

import ArrowBackIcon from "web/assets/icons/arrow-long-left.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import urls from "web/constants/urls";

import { Nullable } from "web/types/Utils";

import List from "./List";
import classes from "./categories.scss";

interface ICategoriesProps {
  categories: CategoryWithCount[];
  code: string;
  idParent: Nullable<number>;
  idCurrent: number;
  idsChildren: number[];
  idsSiblings: number[];
}

const variants = {
  open: {
    opacity: 1,
    height: "auto",
    overflow: "visible",
  },
  close: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
  },
};

const Categories: FC<ICategoriesProps> = ({
  code,
  categories,
  idParent = [],
  idsChildren = [],
  idsSiblings = [],
  idCurrent,
}) => {
  const [isExpanded, setExpanded] = useState(true);
  const { root_category_id: rootCategoryId } = useSelector(
    (state) => state.app.storeConfig
  );
  const { search, pathname } = useLocation();
  const homePageData = {
    request_path: urls.home,
    name: __("Strona Główna"),
  };

  const [parentCategory, categoriesToShow] = useMemo(() => {
    let parentCategoryMemo;
    if (rootCategoryId === idParent) {
      const params = new URLSearchParams(search);
      params.delete(code);
      parentCategoryMemo = {
        all_categories: true,
        request_path: `${pathname}?${params.toString()}`,
        name: __("Wszystkie kategorie"),
      };
    } else {
      const parentCategoryById =
        idParent &&
        isArrayHasItems(categories) &&
        categories.find(
          (categoryItem) =>
            categoryItem && `${categoryItem.id}` === `${idParent}`
        );
      parentCategoryMemo = parentCategoryById || homePageData;
    }

    if (isArrayHasItems(categories)) {
      const categoriesChildrenToShow = isArrayHasItems(idsChildren)
        ? categories.filter(
            (categoryItem) =>
              categoryItem &&
              idsChildren.some((id) => `${id}` === `${categoryItem.id}`)
          )
        : [];
      const categoriesSiblingsToShow = isArrayHasItems(idsSiblings)
        ? categories.filter(
            (categoryItem) =>
              categoryItem &&
              idsSiblings.some((id) => `${id}` === `${categoryItem.id}`)
          )
        : [];
      const categoriesToShowMemo = isArrayHasItems(categoriesChildrenToShow)
        ? categoriesChildrenToShow
        : categoriesSiblingsToShow;

      return [parentCategoryMemo, categoriesToShowMemo];
    }

    return [parentCategoryMemo, []];
  }, [
    idParent,
    JSON.stringify(categories),
    homePageData,
    idsSiblings,
    idsChildren,
    rootCategoryId,
    search,
    pathname,
  ]);
  const { push } = useHistory();
  const clickBackHandler = useCallback(() => {
    if (
      homePageData.request_path === parentCategory.request_path ||
      (parentCategory as { all_categories: boolean }).all_categories
    ) {
      push(parentCategory.request_path);
    } else {
      const params = new URLSearchParams(search);
      params.set(
        code,
        encodeURIComponent((parentCategory as CategoryWithCount).id)
      );
      push(`${pathname}?${params.toString()}`);
    }
  }, [push, search, pathname, parentCategory, code]);

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <LinkTransition
          className={classes.linkBack}
          name={parentCategory.name}
          onClick={clickBackHandler}
          dataT1="categories"
        >
          <ArrowBackIcon className={classes.linkBackIcon} width={12} />
          {__("Cofnij do")}
          <span className={classes.linkBackName}>{parentCategory.name}</span>
        </LinkTransition>
        {isArrayHasItems(categoriesToShow) && (
          <button
            type="button"
            className={classes.button}
            onClick={() => setExpanded(!isExpanded)}
            data-t1="categories_set_expanded_button"
            data-testid="categories_set_expanded_button"
          >
            <DropArrow className={classes.arrow} open={!isExpanded} />
          </button>
        )}
      </header>
      {isArrayHasItems(categoriesToShow) && (
        <motion.div
          className={classes.content}
          animate={isExpanded ? "open" : "close"}
          variants={variants}
        >
          <div className={classes.wrapper}>
            <List
              categories={categoriesToShow}
              idCurrent={idCurrent}
              code={code}
            />
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Categories;
