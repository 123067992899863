/* eslint-disable @typescript-eslint/ban-ts-comment */
// @TODO refactor this component, split code and then get back to unit testing
import { FC, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";

import { CategoryWithCount } from "web/Components/Common/Categories/Desktop/List/list";

import msCategoryPaths from "web/queries/default/categoryPaths.graphql";

import useQueryWithRender from "web/hooks/useQueryWithRender";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getBenefitKeys from "web/utils/system/essentials/getBenefitKeys";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import {
  BENEFIT_PRODUCT_TYPES,
  BENEFIT_URLKEY_TYPES,
} from "web/constants/benefits";
import { searchName } from "web/constants/toolbar";

import { Nullable } from "web/types/Utils";

import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";
import useDataCachedIdsFacetsStats from "web/features/useDataCached/useDataCachedIdsFacetsStats";

import { CommonOptions } from "../fieldset";
import Categories from "./categories";

interface IContainerProps {
  rootCategoryId: number;
  categoryTree: string;
  categoryId: number;
  options: CommonOptions[];
  code: string;
}

const Container: FC<IContainerProps> = memo(
  ({ rootCategoryId, categoryTree, categoryId, options, code }) => {
    const [idsChildren, ids, parentCategoryId, idsSiblings] = useMemo(() => {
      const categoryTreeParsed = jsonParse(categoryTree) as string[];
      const categoryTreeParsedArray = isArrayHasItems(categoryTreeParsed)
        ? categoryTreeParsed
        : [];
      const path = isArrayHasItems(categoryTreeParsedArray)
        ? categoryTreeParsedArray.find((treeItem) => {
            const subPathString = `/${categoryId}`;
            const subPathStringIndex = treeItem.indexOf(subPathString);

            return (
              subPathStringIndex !== -1 &&
              subPathString.length + subPathStringIndex === treeItem.length
            );
          })
        : "";
      const idsChildrenArray =
        isArrayHasItems(categoryTreeParsedArray) && isArrayHasItems(options)
          ? options.reduce<number[]>((result, current) => {
              const currentId = current && +current.value;
              const isCurrentChildrenId = categoryTreeParsedArray.some(
                (treeItem) => {
                  const pathWithId = `${path}/${currentId}`;
                  const pathWithIdIndex = treeItem.indexOf(pathWithId);

                  return (
                    pathWithIdIndex === 0 &&
                    pathWithId.length === treeItem.length
                  );
                }
              );

              return isCurrentChildrenId ? [...result, currentId] : result;
            }, [])
          : [];

      const idsSiblingsArray =
        !isArrayHasItems(idsChildrenArray) &&
        categoryId !== rootCategoryId &&
        isArrayHasItems(categoryTreeParsedArray) &&
        isArrayHasItems(options)
          ? options.reduce<number[]>((result, current) => {
              const currentId = current && +current.value;
              const pathSibling = path
                ? path.replace(`/${categoryId}`, `/${currentId}`)
                : "";
              const isCurrentSiblingId = categoryTreeParsedArray.some(
                (itemTree) => {
                  const subPathStringIndex = itemTree.indexOf(pathSibling);
                  return (
                    subPathStringIndex === 0 &&
                    pathSibling.length === itemTree.length
                  );
                }
              );

              return isCurrentSiblingId ? [...result, currentId] : result;
            }, [])
          : [];
      const pathWithoutCurrentId =
        typeof path === "string" ? path.replace(`/${categoryId}`, "") : "";
      const pathWithoutCurrentIdArray = pathWithoutCurrentId
        ? pathWithoutCurrentId.split("/")
        : [];
      const parentCategoryIdCurrent =
        +pathWithoutCurrentIdArray[pathWithoutCurrentIdArray.length - 1];
      const isCurrentIsRoot = rootCategoryId === categoryId;

      const idsArray = [
        ...idsChildrenArray,
        ...(isCurrentIsRoot ? [] : [parentCategoryIdCurrent]),
        ...idsSiblingsArray,
      ].map((id) => +id);
      const idsUnique = Array.from(new Set(idsArray));

      return [
        idsChildrenArray,
        idsUnique,
        isCurrentIsRoot ? null : parentCategoryIdCurrent,
        idsSiblingsArray,
      ];
    }, [rootCategoryId, categoryId, categoryTree, options]);

    const { loading, error, data } = useDataCachedCategories({
      ids,
    });

    switch (true) {
      case !!loading: {
        return <Loading />;
      }
      case !!error: {
        return <ErrorComponent />;
      }
      default: {
        const categoriesProcessed: CategoryWithCount[] =
          isArrayHasItems(data) && isArrayHasItems(options)
            ? data.map((dataItem) => {
                const currentOption = options.find(
                  (option) => +option.value === +dataItem.id
                );
                return {
                  ...dataItem,
                  count: currentOption && currentOption.count,
                };
              })
            : [];

        const isSubscriptions = categoriesProcessed.some((el) =>
          el.url_key.includes(BENEFIT_URLKEY_TYPES.GROUP_BOTH)
        );

        return !isSubscriptions ? (
          <Categories
            code={code}
            idCurrent={categoryId}
            categories={categoriesProcessed}
            idsChildren={idsChildren}
            idParent={parentCategoryId}
            idsSiblings={idsSiblings}
          />
        ) : (
          <CategoriesWithSubscriptions
            code={code}
            idCurrent={categoryId}
            categories={categoriesProcessed}
            idsChildren={idsChildren}
            idParent={parentCategoryId}
            idsSiblings={idsSiblings}
          />
        );
      }
    }
  }
);

interface ICategoriesWithSubscriptionsProps {
  code: string;
  idCurrent: number;
  categories: CategoryWithCount[];
  idsChildren: number[];
  idParent: Nullable<number>;
  idsSiblings: number[];
}

const CategoriesWithSubscriptions: FC<ICategoriesWithSubscriptionsProps> = ({
  code,
  idCurrent,
  categories,
  idsChildren,
  idParent,
  idsSiblings,
}) => {
  const { search } = useLocation();

  const searchFromUrl = getSearchParameter({ name: searchName, search });

  const opt = {
    variables: {
      search: searchFromUrl?.replace("&", ""),
      facet: true,
    },
    context: {
      clientName: "ms",
    },
    fetchPolicy: "cache-and-network",
  };

  const { loading: loadingDataSearch, data: dataSearch } =
    useDataCachedIdsFacetsStats({
      options: JSON.stringify(opt),
      isSearch: true,
    });

  const subscriptionItems = dataSearch?.subscription_items ?? [];

  const dataFiltered = useSelector((state) => state.benefitGroups);
  const isLoadingFilteredSub = dataFiltered?.isLoading;

  const filteredSubSkuArray = getBenefitKeys(dataFiltered ?? null);

  const validSubscriptionIds = subscriptionItems?.reduce((res, curr) => {
    if (filteredSubSkuArray.includes(curr.sku)) {
      res.push(+curr.id);
    }
    return res;
  }, [] as number[]);

  const categoriesWithSubscriptions = categories.reduce((res, curr) => {
    if (curr.url_key === BENEFIT_URLKEY_TYPES.GROUP_BOTH) {
      if (validSubscriptionIds?.length)
        res.push({
          ...curr,
          count: validSubscriptionIds.length,
        });
    } else if (curr.url_key === BENEFIT_URLKEY_TYPES.GROUP_2) {
      const subscriptionCategoryCount2 = validSubscriptionIds.reduce(
        (count, id) => {
          const item = subscriptionItems.find(
            (subItem) =>
              subItem.id === id.toString() &&
              subItem.subscription_group === BENEFIT_PRODUCT_TYPES.GROUP_2
          );
          return count + (item ? 1 : 0);
        },
        0
      );
      if (subscriptionCategoryCount2 > 0)
        res.push({
          ...curr,
          count: subscriptionCategoryCount2,
        });
    } else if (curr.url_key === BENEFIT_URLKEY_TYPES.GROUP_1) {
      const subscriptionCategoryCount1 = validSubscriptionIds.reduce(
        (count, id) => {
          const item = subscriptionItems.find(
            (subItem) =>
              subItem.id === id.toString() &&
              subItem.subscription_group === BENEFIT_PRODUCT_TYPES.GROUP_1
          );
          return count + (item ? 1 : 0);
        },
        0
      );
      if (subscriptionCategoryCount1 > 0)
        res.push({
          ...curr,
          count: subscriptionCategoryCount1,
        });
    } else {
      res.push(curr);
    }

    return res;
  }, [] as CategoryWithCount[]);

  const isLoading = loadingDataSearch || isLoadingFilteredSub;

  return isLoading ? null : (
    <Categories
      code={code}
      idCurrent={idCurrent}
      categories={categoriesWithSubscriptions}
      idsChildren={idsChildren}
      idParent={idParent}
      idsSiblings={idsSiblings}
    />
  );
};

interface IContainerWithSearchIdProps {
  rootCategoryId: number;
  categoryTree: string;
  code: string;
  options: CommonOptions[];
}

const ContainerWithSearchId: FC<IContainerWithSearchIdProps> = ({
  rootCategoryId,
  categoryTree,
  code,
  options,
}) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const idFromUrl = +(params.get(code) as string);
  const idFromUrlProcessed =
    idFromUrl && options.some((option) => +option.value === idFromUrl)
      ? idFromUrl
      : rootCategoryId;

  return (
    <Container
      code={code}
      options={options}
      rootCategoryId={rootCategoryId}
      categoryId={idFromUrlProcessed}
      categoryTree={categoryTree}
    />
  );
};

interface IContainerWithTreeProps {
  code: string;
  options: CommonOptions[];
}

const ContainerWithTree: FC<IContainerWithTreeProps> = ({ options, code }) => {
  const {
    id: storeId,
    token,
    root_category_id: rootCategoryId,
  } = useSelector((state) => state.app.storeConfig);
  const optionsQuery = {
    variables: {
      token,
      storeId: +storeId,
    },
    context: {
      clientName: "magento",
    },
  };

  const result = useQueryWithRender(
    msCategoryPaths,
    optionsQuery,
    // @ts-ignore
    ErrorComponent,
    Loading
  );

  return result &&
    Object.prototype.hasOwnProperty.call(result, "msCategoryPaths") ? (
    <ContainerWithSearchId
      options={options}
      rootCategoryId={rootCategoryId}
      categoryTree={result.msCategoryPaths}
      code={code}
    />
  ) : (
    result
  );
};

export default ContainerWithTree;
