import { FC } from "react";

import styles from "./fieldPreview.scss";

interface IFieldPreviewProps {
  label: string;
  value?: string;
  id?: string;
}

const FieldPreview: FC<IFieldPreviewProps> = ({ label, value = "", id }) => (
  <p className={styles.field} title={value} data-1={id}>
    {label}: <strong>{value?.length ? value : "-"}</strong>
  </p>
);

export default FieldPreview;
