import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import SubscriptionReceiverDataPreview from "web/Layout/SubscriptionReceivers/SubscriptionReceiver/SubscriptionReceiverDataPreview";
import SubscriptionReceiverDuplicateCard from "web/Layout/SubscriptionReceivers/SubscriptionReceiverDuplicateCard";
import SubscriptionReceiverForm from "web/Layout/SubscriptionReceivers/SubscriptionReceiverForm";
import __ from "web/Layout/Translations";

import getFormMode from "web/utils/page/product/subscription/forms/getFormMode";
import { getMultivalueFromOptions } from "web/utils/page/product/subscription/forms/prepareFormData";

import {
  FormView,
  HandleToggleReceiversModalFn,
  IDuplicateCardData,
  ISubscriptionFormData,
  ISubscriptionFormsFields,
  ISubscriptionReceiver,
  SubscriptionCheckoutSteps,
  SubscriptionFormReceiver,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import { useGetEmployeeConfigurationQuery } from "web/features/employee/employeeApiSlice";
import { getEmployeeFieldsByGroupType } from "web/features/employee/utils";

import styles from "./subscriptionReceiver.scss";

interface ISubscriptionReceiverProps {
  data: ISubscriptionReceiver;
  index?: number;
  subscriptionId: string;
  wayChooseOthersInActiveForm?: SubscriptionFormReceiver;
  duplicateCardData?: Nullable<IDuplicateCardData>;
  viewType: FormView;
  configuratorStep?: SubscriptionCheckoutSteps;
  enableOrderDuplicateCard: boolean;
  duplicateTrigger: boolean;
  openDuplicateForm: Nullable<string>;
  setOpenDuplicateForm: React.Dispatch<React.SetStateAction<Nullable<string>>>;
  updateReceiversDataFunc?: (newReceiverData: ISubscriptionReceiver) => void;
  handleToggleReceiversModal?: HandleToggleReceiversModalFn;
}

const SubscriptionReceiver: FC<ISubscriptionReceiverProps> = ({
  data,
  index = 0,
  subscriptionId,
  wayChooseOthersInActiveForm = SubscriptionFormReceiver.EMPLOYEE,
  duplicateCardData,
  viewType,
  configuratorStep = SubscriptionCheckoutSteps.INFORMATIONS,
  enableOrderDuplicateCard,
  openDuplicateForm,
  duplicateTrigger,
  handleToggleReceiversModal,
  setOpenDuplicateForm,
  updateReceiversDataFunc,
}) => {
  const { benefitGroupType } = useSelector((state) => state.subscriptionQuote);
  const { data: employeeConfig } = useGetEmployeeConfigurationQuery();
  const {
    subscriptionAllowChangingPersonalData,
    formEditInfoBlockEmployee,
    formEditInfoBlockOthers,
  } = getEmployeeFieldsByGroupType(
    [
      "subscriptionAllowChangingPersonalData",
      "formEditInfoBlockEmployee",
      "formEditInfoBlockOthers",
    ],
    benefitGroupType,
    employeeConfig
    // TODO: fix types
  ) as { [key: string]: Nullable<string> };

  const [formData, updateFormData] = useState<ISubscriptionFormData>({
    edited: false,
    fields: {},
    filledInByUser: false,
    error: false,
  });
  const fieldsRef = useRef({});
  const { form, id: receiverId, type: receiverType } = data;

  if (typeof form !== "object") return null;

  const { fields, itemActive, receiverEmail, type: formType } = form;
  const mode = getFormMode({
    form,
    formData,
    receiverType,
    subscriptionAllowChangingPersonalData,
    wayChooseOthersInActiveForm,
    configuratorStep,
  });
  const editInfoBlock =
    receiverType === SubscriptionFormReceiver.EMPLOYEE
      ? formEditInfoBlockEmployee
      : formEditInfoBlockOthers;

  useEffect(() => {
    const formFields: ISubscriptionFormsFields = {};

    fields.forEach(({ id, options, value, type }) => {
      formFields[id] = value || getMultivalueFromOptions(options || [], type);
    });

    if (mode.externalForm) {
      formFields.receiverEmail = receiverEmail ?? "";
    }

    // save preview data not changed in Formik - i.e. firstname, lastname for external forms
    fieldsRef.current = formFields;

    updateFormData({ ...formData, fields: formFields });
  }, [data]);

  return (
    <article
      className={`${styles.root} ${
        mode.preview ? styles["root--previewMode"] : ""
      }`}
    >
      <h5 className={styles.heading}>{`${index + 1}. ${__("osoba")}:`}</h5>
      {mode.preview && (
        <SubscriptionReceiverDataPreview
          fields={fields}
          formData={formData}
          formType={formType}
          mode={mode}
        />
      )}
      {(!duplicateTrigger || viewType === FormView.CONFIGURATOR) &&
       (formData.fields && Object.keys(formData.fields).length > 0) 
       && (
        <SubscriptionReceiverForm
          data={form}
          editInfoBlock={editInfoBlock}
          formData={formData}
          mode={mode}
          receiverId={receiverId}
          receiverType={receiverType}
          subscriptionId={subscriptionId}
          viewType={viewType}
          updateFormData={updateFormData}
          handleToggleReceiversModal={handleToggleReceiversModal}
          fieldsRef={fieldsRef}
        />
      )}
      {itemActive &&
        enableOrderDuplicateCard &&
        duplicateCardData &&
        duplicateTrigger && (
          <SubscriptionReceiverDuplicateCard
            classes={styles}
            data={data}
            duplicateCardData={duplicateCardData}
            enableOrderDuplicateCard={enableOrderDuplicateCard}
            openDuplicateForm={openDuplicateForm}
            setOpenDuplicateForm={setOpenDuplicateForm}
            updateReceiversDataFunc={updateReceiversDataFunc}
          />
        )}
    </article>
  );
};

export default SubscriptionReceiver;
