/* eslint-disable @typescript-eslint/ban-ts-comment */
import { motion } from "framer-motion";
import { FC, useCallback } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";

import __ from "web/Layout/Translations";

import ArrowUpIcon from "web/assets/icons/arrow-up.svg";

import useDropdown from "web/hooks/useDropdown";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { pageName, sortDirectionName, sortName } from "web/constants/toolbar";

import type { PropsWithClasses } from "web/types/Common";
import { Nullable } from "web/types/Utils";

import classify from "web/classify";

import Dropdown from "./Dropdown";
import type {
  ISortFilterNewContainerProps,
  SortOptions,
  SortOptionsKeys,
  SortOptionsProperty,
} from "./container";
import defaultStyles from "./sortFilter.scss";

const dropdownVariants = {
  open: {
    height: "auto",
  },
  closed: {
    height: 0,
  },
};
const transition = { duration: 0.25 };

const SortFilter: FC<PropsWithClasses<ISortFilterNewContainerProps>> = ({
  options,
  classes = {},
  darkMode = false,
}) => {
  const [isOpen, setIsOpen] = useDropdown({
    scopeSelector: `.${classes.root}`,
  });
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const nameFromUrl = params.get(sortName);
  const directionFromUrl = params.get(sortDirectionName);
  const defaultOption = (options.find(
    (option) => (option as SortOptions).default
  ) || options[0]) as SortOptionsProperty;
  const initialName =
    nameFromUrl && options.some((option) => option.name === nameFromUrl)
      ? nameFromUrl
      : defaultOption.name;
  const directionFirstName =
    defaultOption.direction &&
    defaultOption.direction[0] &&
    defaultOption.direction[0].name
      ? defaultOption.direction[0].name
      : null;
  const initialDirection =
    directionFromUrl &&
    (options as SortOptions[SortOptionsKeys][]).some(
      (option) =>
        isArrayHasItems(option.direction) &&
        option.direction.some(
          (directionItem) =>
            (directionItem as { name: string; label: string }).name ===
            directionFromUrl
        )
    )
      ? directionFromUrl
      : directionFirstName;
  const activeNameData = options.find(
    (option) => option.name === initialName
  ) as SortOptionsProperty;
  const activeDirectionData: Nullable<{ name: string; label: string }> =
    activeNameData && activeNameData.direction
      ? // @ts-ignore
        activeNameData.direction.find((item) => item.name === initialDirection)
      : null;
  const activeNameLabel =
    activeNameData && activeNameData.label ? activeNameData.label : "";
  const activeDirectionLabel =
    activeDirectionData && activeDirectionData.label
      ? `: ${activeDirectionData.label}`
      : "";
  const activeLabel = `${__(activeNameLabel)}${__(
    activeDirectionLabel
  )}`.trim();
  const classNameWrapperMode = darkMode
    ? classes.wrapperDarkActive
    : classes.wrapperActive;
  const classNameWrapper = isOpen ? classNameWrapperMode : classes.wrapper;
  const changeHandler = useCallback(
    ({ name, direction }: { name: string; direction?: string }) => {
      if (name && direction) {
        params.set(sortName, encodeURIComponent(name));
        params.set(sortDirectionName, encodeURIComponent(direction));
        params.delete(pageName);
      } else {
        params.delete(sortName);
        params.delete(sortDirectionName);
      }

      push(`${pathname}?${params.toString()}`);
      setIsOpen((status) => !status);
    },
    [setIsOpen, params, pathname, push]
  );

  return (
    <div className={classes.root}>
      <div className={classNameWrapper}>
        <button
          type="button"
          className={classes.trigger}
          onClick={() => setIsOpen((state) => !state)}
          data-t1="sort_filter_button"
        >
          {activeLabel} <ArrowUpIcon className={classes.arrow} />
        </button>
        <div className={classes.body}>
          <motion.div
            className={classes.dropdown}
            animate={isOpen ? "open" : "closed"}
            transition={transition}
            variants={dropdownVariants}
          >
            <Dropdown
              options={options}
              onChange={changeHandler}
              darkMode={darkMode}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default classify<PropsWithClasses<ISortFilterNewContainerProps>>(
  defaultStyles
)(SortFilter);
