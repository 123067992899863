import { Button } from "@benefit-systems/common-components";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { InformationStepCtx } from "web/Pages/Checkout/Subscription/InformationStep/withContext";

import Modal from "web/Layout/SubscriptionModal";
import ActiveForm from "web/Layout/SubscriptionReceivers/SubscriptionReceiverForm/ActiveForm";
import ActiveFormPreview from "web/Layout/SubscriptionReceivers/SubscriptionReceiverForm/ActiveFormPreview";
import ReceiverEmailForm from "web/Layout/SubscriptionReceivers/SubscriptionReceiverForm/ReceiverEmailForm";
import SimpleForm from "web/Layout/SubscriptionReceivers/SubscriptionReceiverForm/SimpleForm";
import __ from "web/Layout/Translations";

import HtmlTemplate from "web/Components/Common/HtmlTemplate";
import ToolTip from "web/Components/Common/ToolTip";

import EditIcon from "web/assets/icons/edit.svg";

import { ErrorData } from "web/api/apiRequestTypes";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import editForm from "web/utils/page/product/subscription/forms/editForm";
import { removeVirtualFields } from "web/utils/page/product/subscription/forms/prepareFormData";
import getErrorInfo from "web/utils/system/essentials/getErrorInfo";

import {
  FormType,
  FormView,
  HandleToggleReceiversModalFn,
  ISubscriptionFormData,
  ISubscriptionFormMode,
  ISubscriptionReceiverActiveForm,
  ISubscriptionReceiverForm,
  ISubscriptionReceiverFormValues,
  SubscriptionFormActions,
  SubscriptionFormReceiver,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import { useAppContext } from "web/context/app";
import {
  setNotificationError,
  setNotificationSuccess,
} from "web/features/app/appSlice";

import styles from "./subscriptionReceiverForm.scss";

interface ISubscriptionReceiverFormProps {
  data: ISubscriptionReceiverForm;
  editInfoBlock: Nullable<string>;
  fieldsRef: React.MutableRefObject<ISubscriptionReceiverFormValues>;
  formData: ISubscriptionFormData;
  mode: ISubscriptionFormMode;
  receiverId: string;
  receiverType: SubscriptionFormReceiver;
  subscriptionId: string;
  viewType: FormView;
  updateFormData: (data: ISubscriptionFormData) => void;
  handleToggleReceiversModal?: HandleToggleReceiversModalFn;
}

const messages = {
  employeeEditDisabled:
    "Automatycznie zapisaliśmy Twoje dane, ponieważ ten abonament możesz wybrać tylko dla siebie.",
  formSaveSuccess: "Nowe dane zostały zapisane.",
  formSaveError: "Nowe dane nie zostały zapisane.",
  editEmailSuccess: "Na podany e-mail został wysłany formularz.",
  editEmailError: "Nie udało się wysłać maila, spróbuj ponownie później.",
};

const SubscriptionReceiverForm: FC<ISubscriptionReceiverFormProps> = ({
  data,
  editInfoBlock,
  formData,
  mode,
  receiverId,
  receiverType,
  subscriptionId,
  viewType = FormView.CONFIGURATOR,
  updateFormData,
  handleToggleReceiversModal,
  fieldsRef,
}) => {
  const dispatch = useDispatch();
  const { modal } = useAppContext();
  const { dispatch: mDispatch } = modal;
  const { saveForm, formsError } = useContext(InformationStepCtx) ?? {};
  const {
    activeForm = {},
    awaitsUpdate,
    fields = [],
    type: formType,
    id: formId,
    itemActive,
    receiverEmail,
    submitted,
  } = data ?? {};
  const [isFormDataEmpty, setIsFormDataEmpty] = useState(true);

  useEffect(() => {
    // Sprawdzenie czy formData zawiera dane + dodatkowo sprawdzenie czy fields nie jest pustym obiektem {}
    if (formData && formData.fields && Object.keys(formData.fields).length > 0) {
      setIsFormDataEmpty(false);
    } else {
      setIsFormDataEmpty(true);
    }
  }, [formData])

  const {
    configuration = [],
    description,
    name,
  } = (activeForm as ISubscriptionReceiverActiveForm) ?? {};
  const { error = false } = formsError?.[formId] ?? {};

  const setModal = useCallback(() => {
    mDispatch({
      type: ModalActionTypes.ADD_MODAL,
      modal: (
        <Modal
          title={name ?? __("Formularz świadczeń abonamentowych")}
          description={description}
        >
          {formType === FormType.ACTIVE_FORM ? (
            <>
              {mode.externalForm ? (
                <ReceiverEmailForm
                  formData={formData}
                  receiverEmail={receiverEmail}
                  submitHandler={submitHandler}
                  resend={mode.resend}
                />
              ) : (
                <ActiveForm
                  configuration={configuration}
                  fields={fields}
                  formData={formData}
                  nameFieldsDisabled={mode.nameFieldsDisabled}
                  submitHandler={submitHandler}
                />
              )}
            </>
          ) : (
            <SimpleForm
              fields={fields}
              nameFieldsDisabled={mode.nameFieldsDisabled}
              formData={formData}
              submitHandler={submitHandler}
            />
          )}
        </Modal>
      ),
    });
  }, [mDispatch, formData]);

  const setPreviewModal = useCallback(() => {
    mDispatch({
      type: ModalActionTypes.ADD_MODAL,
      modal: (
        <Modal
          title={name ?? __("Formularz świadczeń abonamentowych")}
          description={description}
        >
          <ActiveFormPreview
            configuration={configuration}
            formData={formData}
          />
        </Modal>
      ),
    });
  }, [mDispatch, formData]);

  const closeModal = useCallback(() => {
    mDispatch({ type: ModalActionTypes.RESET });
  }, [mDispatch]);

  const submitHandler = (values: ISubscriptionReceiverFormValues) => {
    const writableValues = removeVirtualFields(values);
    // if form is on active subscription - send singly to /edit-receiver-form
    if (submitted || itemActive || awaitsUpdate) {
      editForm({
        subscriptionId,
        receiverId,
        receiverType,
        ...(mode.externalForm
          ? { receiverEmail: writableValues.receiverEmail as string }
          : { fields: writableValues }),
        viewType,
        onSuccess: () => {
          // On certain views, e.g. My Subscriptions recivers are rendered in modals.
          // Forms are always display in modal, so if one was invoked from modal it needs to be restored on save.
          if (typeof handleToggleReceiversModal === "function") {
            handleToggleReceiversModal({
              id: receiverId,
              ...(mode.externalForm
                ? { receiverEmail: writableValues.receiverEmail as string }
                : { fields: writableValues }),
            });
          }
          handleSubmitSuccess(values);
        },
        onError: (error: ErrorData) => handleSubmitError(error),
      });
    } else if (typeof saveForm === "function") {
      // if form is on newly chosen subscription - save to context & send on next step
      saveForm({
        formId,
        currentValues: writableValues,
        receiverId,
        subscriptionId,
      });
      updateFormData({
        ...formData,
        fields: writableValues,
        filledInByUser: true,
      });
      closeModal();
    }
  };

  const handleSubmitSuccess = useCallback(
    (values: ISubscriptionReceiverFormValues) => {
      const writableValues = removeVirtualFields(values);

      updateFormData({
        fields: {
          ...fieldsRef.current,
          ...writableValues,
        },
        filledInByUser: true,
        edited: true,
        error: false,
      });

      if (typeof saveForm === "function") {
        saveForm({
          formId,
          currentValues: {},
          receiverId,
          updated: true,
          subscriptionId,
        });
      }

      dispatch(setNotificationSuccess({ message: messages.formSaveSuccess }));

      if (typeof handleToggleReceiversModal !== "function") closeModal();
    },
    []
  );

  const handleSubmitError = useCallback(
    (error: ErrorData) => {
      const additionalInfo = getErrorInfo(error);
      const errorMessage = additionalInfo.violations?.[0]?.message;

      dispatch(
        setNotificationError({
          message:
            mode.actions === SubscriptionFormActions.RESEND
              ? __(messages.editEmailError)
              : __(errorMessage || messages.formSaveError),
        })
      );
      if (typeof handleToggleReceiversModal !== "function") closeModal();
    },
    [dispatch]
  );

  const formActions = (() => {
    let actions;

    switch (mode.actions) {
      case SubscriptionFormActions.NONE:
        actions = null;
        break;
      case SubscriptionFormActions.PREVIEW_AND_EDIT:
        actions = (
          <>
            <Button variant="secondary" onClick={setPreviewModal} disabled={isFormDataEmpty}>
              {__("Zobacz formularz")}
            </Button>
            <Button variant="secondary" onClick={setModal}>
              <>
                <EditIcon className={styles.icon} />
                <span>{__("Edytuj")}</span>
              </>
            </Button>
          </>
        );
        break;
      case SubscriptionFormActions.PREVIEW:
        actions = (
          <Button variant="secondary" onClick={setPreviewModal} disabled={isFormDataEmpty}>
            {__("Zobacz formularz")}
          </Button>
        );
        break;
      case SubscriptionFormActions.PREVIEW_OTHERS:
        actions = (
          <Button
            className={styles.editButton}
            type="button"
            onClick={setPreviewModal}
            disabled={isFormDataEmpty}
          >
            {__("Zobacz formularz")}
          </Button>
        );
        break;
      case SubscriptionFormActions.EDIT:
        actions = (
          <>
            <button
              className={`${styles.editButton} ${
                mode.awaitsUpdate &&
                !mode.resend &&
                !formData.edited &&
                !formData.filledInByUser
                  ? styles["editButton--error"]
                  : ""
              }`}
              type="button"
              onClick={setModal}
              disabled={mode.employeeEditDisabled}
            >
              {__("Edytuj dane")}
            </button>
            {mode.employeeEditDisabled && (
              <ToolTip message={__(messages.employeeEditDisabled)} />
            )}
          </>
        );
        break;
      case SubscriptionFormActions.RESEND:
        actions = (
          <button
            className={`${styles.editButton} ${styles["editButton--error"]}`}
            type="button"
            onClick={setModal}
          >
            {__("Wyślij ponownie formularz")}
          </button>
        );
        break;
      default:
        actions = (
          <Button
            variant="primary"
            onClick={setModal}
            className={error || mode.awaitsUpdate ? styles.error : ""}
          >
            {__("Wypełnij formularz")}
          </Button>
        );
    }

    return actions;
  })();

  return (
    <div
      className={`${styles.root} ${
        mode.preview ? styles["root--previewMode"] : ""
      }`}
    >
      {formActions}
      {editInfoBlock && formData.edited && (
        <div className={styles.message}>
          <strong className={styles.message__header}>
            {__(
              mode.externalForm
                ? messages.editEmailSuccess
                : messages.formSaveSuccess
            )}
          </strong>
          <HtmlTemplate className="cke_editable" template={editInfoBlock} />
        </div>
      )}
    </div>
  );
};

export default SubscriptionReceiverForm;
