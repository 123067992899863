/* eslint-disable @typescript-eslint/ban-ts-comment */
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { CommonOptions } from "../../fieldset";

const sortItem = (
  items: CommonOptions[],
  sortMethod: (first: string, second: string) => number,
  sortMethodName: string
) => {
  return isArrayHasItems(items)
    ? items.slice().sort((first, second) => {
        if (
          typeof sortMethod === "function" &&
          typeof sortMethodName === "string"
        ) {
          const key = sortMethodName === "alphabet" ? "label" : "count";
          // @ts-ignore
          return sortMethod(first[key], second[key]);
        }
        return 0;
      })
    : [];
};

export default sortItem;
