import { FC, useCallback, useMemo } from "react";

import __ from "web/Layout/Translations";

import HeartIcon from "web/assets/icons/heart-empty.svg";
import HeartFullIcon from "web/assets/icons/heart-fill.svg";
import TrashIcon from "web/assets/icons/trash.svg";

import type { PropsWithClasses } from "web/types/Common";
import type { IProduct } from "web/types/Product";

import classify from "web/classify";
import { useAppContext } from "web/context/app";
import {
  useDeleteWishlistItemMutation,
  useGetWishlistQuery,
  usePostWishlistItemMutation,
} from "web/features/wishlist/wishlistApiSlice";

import defaultClasses from "./productFavorite.scss";

interface IProductCardFavoriteProps {
  sku: IProduct["sku"];
  id: IProduct["id"];
  isWishlistPage?: boolean;
}

const ProductCardFavorite: FC<PropsWithClasses<IProductCardFavoriteProps>> = ({
  classes = {},
  sku,
  id,
  isWishlistPage = false,
}) => {
  const { data, isLoading, isFetching } = useGetWishlistQuery();
  const [postWishlistItem, { isLoading: isAddingCurrent }] =
    usePostWishlistItemMutation();
  const [deleteWishlistItem, { isLoading: isDeletingCurrent }] =
    useDeleteWishlistItemMutation();
  const wishlistProducts = data?.items ?? [];

  const productOnWishlist = useMemo(
    () => wishlistProducts.find((item) => item && +item.product_id === +id),
    [wishlistProducts, id]
  );

  const handleStateChange = useCallback(() => {
    if (isWishlistPage || productOnWishlist) {
      deleteWishlistItem(productOnWishlist?.item_id || "");
    } else {
      postWishlistItem(sku);
    }
  }, [productOnWishlist, sku, id]);

  const { isMobile } = useAppContext();

  return isWishlistPage ? (
    <button
      type="button"
      onClick={handleStateChange}
      disabled={isAddingCurrent || isDeletingCurrent || isLoading || isFetching}
      className={classes.root}
      data-t1="product_favorite_button"
    >
      <span className={classes.label} data-t1="product_favorite_button_label">
        {__("Usuń")}
      </span>
      {!isMobile && <TrashIcon className={classes.trashDesktop} />}
      <TrashIcon className={classes.trash} />
    </button>
  ) : (
    <button
      type="button"
      disabled={isAddingCurrent || isDeletingCurrent || isLoading || isFetching}
      onClick={handleStateChange}
      className={classes.root}
      data-t1="product_favorite_button_heart"
      aria-label="Wishlist toggle"
    >
      {productOnWishlist ? (
        <HeartFullIcon width={22} />
      ) : (
        <HeartIcon width={22} />
      )}
    </button>
  );
};

export default classify<PropsWithClasses<IProductCardFavoriteProps>>(
  defaultClasses
)(ProductCardFavorite);
